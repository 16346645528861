import React, { useState, useEffect, useContext } from "react"
import gql from "graphql-tag"
import { useMutation } from "@apollo/client"
import {
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Fade,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core"

import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import { makeStyles } from "@material-ui/core/styles"

import Loading from "../Utils/Loading"
import Error from "../Utils/Error"
import UserContext from "../../context/UserContext"
// import { GoogleLogin } from "react-google-login"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  signInCard: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "15px",
    backgroundColor: "rgba(255,255,255,0.85)",
    border: "2px solid #fff",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    opacity: "1.0",
  },
  text: {
    backgroundColor: "rgba(255,255,255,0.85)",
  },

  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const SignIn = ({ setIsLoggedIn }) => {
  const classes = useStyles()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { state, dispatch } = useContext(UserContext)
  // const [accessToken, setAccessToken] = useState("")

  // useEffect(() => {
  //   if (accessToken.length > 10) {
  //     handleGoogleAuth()
  //   }
  // }, [accessToken])

  const [handleSubmit, { loading, error }] = useMutation(LOGIN_MUTATION, {
    variables: {
      email: email,
      password: password,
    },
    onCompleted: (response, errors) => {
      setEmail("")
      setPassword("")
      dispatch({ type: "SET_ILLINOIS", payload: true })
      setIsLoggedIn(true)
    },
    onError: err => console.error(err),
  })

  // const [handleGoogleAuth] = useMutation(GOOGLE_AUTH_MUTATION, {
  //   variables: {
  //     provider: "google-oauth2",
  //     accessToken: accessToken,
  //   },
  //   onCompleted: (data, response, errors) => {
  //     dispatch({ type: "SET_VIRGINIA", payload: true })
  //     dispatch({ type: "SET_ILLINOIS", payload: true })
  //     setIsLoggedIn(true)
  //     console.log(data)
  //     console.log("signed in with Google")
  //   },
  //   onError: err => console.error(err),
  // })

  // const responseGoogle = response => {
  //   console.log(response)
  //   setAccessToken(response.accessToken)
  // }

  // if (loading) return <Loading />
  if (loading) return <Loading />
  return (
    <Container component="main" maxWidth="xs" height="100%">
      {/* <div className={classes.paper}> */}
      <Fade in>
        <Card className={classes.signInCard}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form
            className={classes.form}
            noValidate
            onSubmit={event => {
              event.preventDefault()
              handleSubmit(event)
            }}
          >
            <TextField
              className={classes.text}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
            <TextField
              className={classes.text}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={event => setPassword(event.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading || !email.trim() || !password.trim()}
            >
              {loading ? "Just one moment..." : "Sign In"}
            </Button>
            <Grid container>{error && <Error />}</Grid>
          </form>
          <Box mt={4}>
            {/* <GoogleButton onClick={handleGoogleAuth} /> */}
            {/* <GoogleLogin
              clientId="758464376192-b8rmcusj71oh8ao1ogqh8in7gpobdnp5.apps.googleusercontent.com"
              buttonText="Login"
              onSuccess={responseGoogle}
              onFailure={responseGoogle}
              cookiePolicy={"single_host_origin"}
            /> */}
          </Box>
        </Card>
      </Fade>
      {/* </div> */}

      <Box mt={8}>{/* <Copyright /> */}</Box>
    </Container>
  )
}

const LOGIN_MUTATION = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      __typename
    }
  }
`

const GOOGLE_AUTH_MUTATION = gql`
  mutation SocialAuth($provider: String!, $accessToken: String!) {
    socialAuth(provider: $provider, accessToken: $accessToken) {
      social {
        uid
        user {
          id
          person {
            firstName
          }
          isVerified
          staff
          manager
        }
      }
      token
    }
  }
`

export default SignIn
