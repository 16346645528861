import React, { createContext, useEffect } from "react"

const UserContext = createContext({
  illinois: false,
  virginia: false,
  wisconsin: false,
  missouri: false,
  initial: "",
  timestamp: "",
  alertCount: 0,
  homePageCards: [
    "tasks",
    "wideCharts",
    "orders",
    "messages",
    "charts",
    "inquiries",
    "batches",
    "projects",
    "proposals",
    "settings",
  ],
  expandedHome: true,
})

export default UserContext
